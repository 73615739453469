.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  max-width:1600px;
  width:94%;
  margin: 0 auto;

}

.searchSection {
  height: 100vh;
  border-bottom: none;
  padding-bottom: 0;
  background-image: url(../assets/images/cycling-bg.jpg);
  background-size: cover;
  background-position-x: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchSection .container {
    padding-bottom: 50px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
#results {
  margin-top:40px;
  padding: 0 20px;
}

.uk-tab-left li.Compare-List a, .uk-tab-left li.Compare-List a:hover {
  color:#fff;
  font-weight:bold;
  background:#5cb85c;
}
.uk-tab-left .uk-active.Compare-List a:after, .uk-tab-left li.Compare-List:hover a:after,.uk-tab-left .Compare-List a:after {

  background: #5cb85c;
  color:#fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
