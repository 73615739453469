header {
    position:absolute;
    width:100%;
}
.top-bar {
  padding:20px;
}
.menu {
    margin: 0;
    list-style-type: none;
}
.menu img {
    padding-bottom: 10px;
}
.sub-title, .tagline {
    display: block;
    color: #fff;
    font-weight: 400;
    font-size:.6em;
    
}