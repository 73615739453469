input[type=checkbox]~label:after {
    content: ' ';
    width: 66%;
    height: 100%;
   /* background-image: url(logo.png);*/
    background-size: contain;
    background-position: 50%;
    
    display: inline-block;
    background-repeat: no-repeat;
    left: 38px;
    margin-left: -2px;
    transition: all .3s;
    border: 2px solid #ccc;
}


.bicyclestore input[type=checkbox]~label:after {
    content:"";
    background-image: url(../assets/images/the-bicycle-store-logo.jpg);
    background-color: #fff;
}
.bikebug input[type=checkbox]~label:after {
    content:"";
    background-image: url(../assets/images/bikebug-logo.png);
    background-color: #000;
    width: 87%;
}
.bikebug input[type=checkbox]~label:before {
    content:none;
}
.bikeexchange input[type=checkbox]~label:after {
    content:"";
    background-image: url(../assets/images/bikeexchange-logo.png);
    background-color: #fff;
}
.crc input[type=checkbox]~label:after {
    content:"";
    background-image: url(../assets/images/crc-logo.jpg);
    background-color: #fff;
}
.ebay input[type=checkbox]~label:after {
    content:"";
    background-image: url(../assets/images/ebay-logo.png);
    background-color: #fff;
}
.pbk input[type=checkbox]~label:after {
    content:"";
    background-image: url(../assets/images/pbk-logo.jpg);
    background-color: #fff;
}
.pushys input[type=checkbox]~label:after {
    content:"";
    background-image: url(../assets/images/pushys-logo.png);
    background-color: #fff;
}
.tbe input[type=checkbox]~label:after {
    content:"";
    background-image: url(../assets/images/tbe-logo.jpg);
    background-color: #fff;
}
 ._99bikes input[type=checkbox]~label:after {
    content:"";
    background-image: url(../assets/images/99bikes-logo.png);
    background-color: #E12518;
   
}
.wiggle input[type=checkbox]~label:after {
    content:"";
    background-image: url(../assets/images/wiggle-logo.jpg);
    background-color: #3c3c3b;
}


[type=checkbox]+label {
    margin-left: 0;
    width: 100%;
    height: 40px;
    margin-right: 0;
    
}


div#stores {
    max-width: 94%;
    margin: 0 auto;
    width:100%;
    padding-top:80px;
}

div#stores>div {
    display: inline-block;
    width: 18%;
    height: 40px;
    margin:.5%;
    
    
}
input[type=checkbox] {
    position: absolute;
    top: -9999px;
    left: -9999px;
    display: none;
}

input[type=checkbox]~label:before {
    font-family: FontAwesome;
    content: '\f00d';
    border: 2px solid #ccc;
    display: inline-block;
    height: 100%;
    text-align: center;
    line-height: 36px;
    transition: all .3s;
    font-size: 2vw;
    vertical-align: top;
    width: 20%;
    color: #d9534f;
    color: rgba(217,83,79,.8);
    background-color: #fff;
}

input[type=checkbox]:checked~label:before {
    font-family: FontAwesome;
    content: '\f00c';
    color: #5cb85c;
    color: rgba(92,184,92,.8);
    transition: all .3s;
}
.bikebug input[type=checkbox]~label:before {
    content:none;
}

@media screen and (max-width: 1024px) {
input[type=checkbox]~label:before {
    font-size: 3vw;
    width: calc(35% - 4px)
}
input[type=checkbox]~label:after {
    width: calc(65% - 4px);
}
}
@media screen and (max-width: 640px) {
input[type=checkbox]~label:before {
    font-size: 5vw;
}
div#stores>div {
    width: 23.5%;
    height:34px;
}
.searchBtnText {
    display:none;
}
div#stores {
    max-width: 100%;
    margin: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}
}