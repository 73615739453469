.compare-search {
    padding-top: 40px;
}

.compare-search input[type=text] {
    height: 3.4375rem;
    font-size: 1.4rem;
    box-shadow: 0 0 8px rgb(0 0 0 / 50%);
    text-align: center;
    border-radius: 10px;
    border: 2px solid #fff;
}

.compare-search button {
    box-shadow: 0 0 8px rgb(0 0 0 / 50%);
    text-shadow: 1px 1px 0 #666;
    background: #5cb85c;
    padding: .9em 1em;
    font-size: 1.15rem;
    color:#fff;
    display:block;
    margin:20px auto;
    border-radius: 10px;
    border: 2px solid #fff;
}
.search-button, .recent-search {
    cursor:pointer;
}
.history {
    text-align: center;
    margin-top:10px;
}
.history button {
    padding: 8px 12px;
    margin-right: 5px;
   
}
.history .button-group {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
}

@media screen and (max-width: 640px) {
    .compare-search input[type=text] {
        width:100%;
    }
}