.products-wrapper {
    display: flex;
    flex-basis: auto;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .p-wrapper {
    width: 32%;
    margin-bottom: 1.5%;
    position: relative;
  }

 .priceBtn {
    color: #333;
    font-size: 16px;
    overflow: hidden;
    padding: 7px;
    width: 100%;
    height: 70px;
    margin: 0;
}

.priceBtn  span{
    display: inline-block;
    padding: 15px 30px;
    background: #0d6efd!important;
    font-weight: bold;
    border-radius: 3px;
    color: #fff;
    z-index: 2;
}

.products-wrapper .description {
    color: #333;
    font-size: 16px;
    overflow: hidden;
    padding: 7px;
    width: 100%;
    height: 80px;
    margin: 0;
    background: #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.p-wrapper .clickAreaLink {
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;
}
.products-wrapper .img {
    width: 100%;
    text-align: center;
    background: center no-repeat #fff;
    display: inline-block;
    height: 140px;
    background-size: 140px;
    padding: 20px 0;
}
.products-wrapper img {
    height: 140px;
    width: auto;
       
}

.p-wrapper {
    display: flex;
    flex-basis: auto;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    align-items: center;
    position: relative;
    background: #fff;
}

.products-wrapper .logo img {
    height: 40px;
    text-align: center;
}

.products-wrapper .logo {
    text-align: center;
    background: #fff;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding:8px 0;
}

.products-wrapper.wiggle .logo {
    background: #424244;
}

.products-wrapper._99bikes .logo {
    background: #E12518;
}



/*tabs*/

.uk-tab-left ._Wiggle-Cycles {
    background: #424244;
}

.uk-tab-left ._99bikes {
    background: #E12518;
}

.uk-tab-left li a:after {
    content: "\f054";
    position: absolute;
    font-family: 'FontAwesome';
    right: 10px;
    color: #000;
    top: 24px;
}
.uk-tab-left li.uk-active a:after, .uk-tab-left li._Compare-List a:after {
    color:#fff;
}
.uk-tab-left li a {
    color:#000;
    padding:15px;
}
.uk-tab-left li {
    margin:5px;
    border: none;
}

.uk-tab-left .uk-active a:after, .uk-tab-left li:hover a:after {
    
    transform: scale(1.2);
   
    font-weight: bold;
    
}
.uk-tab>.uk-active {
    border:none;
}

.uk-tab>.uk-active>a,  .uk-tab>li>a:hover {
    margin-left: 20px;
  
    font-weight:bold;
}
.tab-link-wrapper {
    width:20%;
    min-width:200px;
}

.link-wrapper {
    position:relative;
    width: 100%;
}
.link-wrapper .priceBtn, .link-wrapper .description  {
    box-sizing:border-box;
}

.bikebugTab {
    list-style: none;
    margin:0;
    padding:0;
    position:relative;
    text-align: left;
   
}
.bikebugTab li {
background: #999;
    padding: 15px;
}

.bikebugTab a { 

  display:block;
  text-transform: uppercase;
  color:#eee;


}

.bikebugTab a:after {
    content: " (new window)";
    right: 10px;
    font-size: 10px;
    padding: 2px;
    line-height: 12px;
    display: block;
    border-radius: 9px;
}
#search-modal {
    text-align:center;
}

.fa-star {
    font-size: 2.2em;
    position: absolute;
    background: #fff;
    height: 56px;
    top: 0;
    padding: 0 10px;
    width: 56px!important;
    right: 0;
}

.compare .fa-star  {
    color:#5cb85c;
}
/* .logo:hover .fa-star {
    transform: scale(1.1);
} */
.compare:active .fa-star {
    color:#666;
}


@media screen and (max-width:640px) {
    .p-wrapper {
        width:100%;
    }
    .products-wrapper img {
        height: 100px;

    }
    .products-wrapper .img {
        padding:0;
        height:95px;
    }
    .priceBtn span {
        padding: 8px 20px;
    }
    .products-wrapper .logo img {
        height: 30px;
    }
    .fa-star {
        height: 46px;
        padding: 0px 10px;
        width: 46px!important;
    }
}