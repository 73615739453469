#results .uk-tab-left li {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}
#results .uk-tab-left li a {
    padding:25px;
}

#results .uk-tab li._Compare-List {
    background:#5cb85c;
    border:none;

}
#results .uk-tab li._Compare-List a {
    color:#fff;
}

#results .uk-tab li.uk-active {
    background:#1e87f0;
}
#results .uk-tab li.uk-active a {
    color:#fff;
}
#results .uk-tab li {
    margin:3px 0;
    width: 100%;
    background:#eee;
}
#results .uk-tab li a {
    color:#333;
}

.uk-badge {
    background-color: rgba(255,255,255,1);
    color: #333;
    margin-top: -2px;
    height: 16px;
}


@media screen and (max-width:640px) {
    .uk-grid>* {
        padding-left: 15px;
    }
    .tab-link-wrapper {
        width: 20%;
    min-width: 135px;
    padding-left: 15px;
    }
    .uk-tab {
        margin-left:0;
    }
    .uk-tab>*>a {
        text-align:left;
        font-size: .775rem;
        padding:10px 6px;
    }
    .uk-tab>* {
        padding-left:0;
        flex:auto;
    }
    .uk-tab>.uk-active>a, .uk-tab>li>a:hover {
        margin-left: 0;
    }
}